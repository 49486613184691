// https://github.com/MartijnCuppens/rfs
@import '~rfs/scss/rfs';

$basefontsize: 20;

.ds-fluid-text,
.ds-cb-html:not(.has-font-size) > *,
ds-cb-link,
ds-cb-contact-card,
ds-cb-staff-card {
  @include rfs($basefontsize);
  line-height: 1.45rem;
}

.ds-fluid-text-xs {
  @include rfs($basefontsize - 4);
  line-height: 1rem;
}

.ds-fluid-text-sm {
  @include rfs($basefontsize - 4);
  line-height: 1.15rem;
}

.ds-fluid-text-lg {
  @include rfs($basefontsize + 5);
  line-height: 1.75rem;
}

.ds-fluid-text-xl {
  @include rfs($basefontsize + 10);
  line-height: 2rem;
}

.ds-fluid-text-xxl {
  @include rfs($basefontsize + 20);
  line-height: 2rem;
}

h3.fluid-text {
  @include rfs($basefontsize + 3);
}

ds-cb-header {
  h2.x-large {
    @include rfs($basefontsize + 30);
    line-height: calc(1.75rem + 1.2666666667vw); // 3rem;
  }
  h2.large {
    @include rfs($basefontsize + 15);
    line-height: calc(1.5rem + 1.2666666667vw); // 2rem;
  }
  h2.medium {
    @include rfs($basefontsize + 10);
  }
  h2.small {
    @include rfs($basefontsize + 5);
  }
  h2.x-small {
    @include rfs($basefontsize);
  }
}
