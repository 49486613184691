@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$input-btn-focus-color: rgba(#cb1c27, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/input-group';

.form-control {
  background-color: var(--ds-form-control-background-color);
  color: var(--ds-form-control-color);
  padding: var(--ds-form-control-padding-x) var(--ds-form-control-padding-y);
  border: var(--ds-form-control-border);

  &:focus {
    border-color: var(--ds-form-control-border-color--focus);
    color: var(--ds-form-control-color--focus);
    background-color: var(--ds-form-control-background-color--focus);
  }
}

.input-group-text {
  border: var(--ds-form-input-group-addon-border);
  background-color: var(--ds-form-input-group-addon-background-color);
  color: var(--ds-form-input-group-addon-color);
}

formly-field {
  // label {
  //   display: block;
  // }
}
