// Creates a list of global breakpoints
$breakpoints: (
  xs: 0,
  sm: 800px,
  // I changed this from 600px to 800px
  md: 960px,
  lg: 1280px,
  xl: 1920px,
) !default;

/* The mixins below are copied from @PlakeSide/bootstrap-scss https://github.com/PlakeSide/bootstrap-scss/blob/master/mixins/_breakpoints.scss as they are required by the CSS below the mixins.
  Currently, only a subset of the breakpoint mixins are included. */
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}
@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
:root {
  --ds-text-color: rgb(88, 88, 90);
  --ds-link-color: #cb1c27;
  --ds-button-color: #fff;
  --ds-button-background-color: #cb1c27;
  --ds-button-border-color: #cb1c27;
  --ds-form-control-border: 1px solid #ced4da;
  --ds-form-control-border-color--focus: #cb1c27;
  --ds-form-control-background-color: #fff;
  --ds-form-control-background-color--focus: #fff;
  --ds-form-control-color: #495057;
  --ds-form-control-color--focus: #495057;
  --ds-form-control-padding-x: 0.375rem;
  --ds-form-control-padding-y: 0.75rem;
  --ds-form-input-group-addon-border: 1px solid #ced4da;
  --ds-form-input-group-addon-background-color: #e9ecef;
  --ds-form-input-group-addon-color: #495057;
  --ds-background-color: #333;
  --ds-main-content-background-color: #fff;
  --ds-cb-section-divider-color: #333;
  --ds-cb-section-max-width: 1170px;
  --ds-cb-nav-background-color: #333;
  --ds-cb-nav-link-color: #fff;
  --ds-cb-nav-item-color: #fff;
  --ds-cb-nav-item-color--active: #333;
  --ds-cb-nav-item-color--hover: #333;
  --ds-cb-nav-item-background-color--active: #fff;
  --ds-cb-nav-item-background-color--hover: #fff;
  --ds-cb-nav-sub-menu-background-color: #fff;
  --ds-cb-nav-sub-menu-color: #333;
  --ds-cb-nav-sub-menu-item-border-left--hover: none;
  --ds-cb-nav-text-color: #fff;
  --ds-cb-nav-logo-height: 60px;
  --ds-cb-card-border-color: #333;
  --ds-cb-card-header-footer-background-color: #cb1c27;
  --ds-cb-card-header-footer-color: inherit;
  --ds-cb-card-header-footer-text-align: left;
  --ds-cb-card-background-color: #fff;
  --ds-cb-footer-background-color: #333;
  --ds-cb-footer-link-color: steelblue;
  --ds-cb-footer-text-color: #fff;
  --ds-cb-footer-card-border-color: #333;
  --ds-cb-footer-card-background-color: #cb1c27;
  --ds-cb-footer-card-header-footer-background-color: #cb1c27;
  --ds-cb-contact-card-link-color: steelblue;
  --ds-cb-contact-card-border-color: #e5e5e5;
  --ds-cb-contact-card-border-color--hover: gray;
  --ds-cb-contact-card-background-color: whitesmoke;
  --ds-cb-contact-card-photo-border-color: gray;
  --ds-cb-image-background-color: white;
  --ds-cb-image-border-color: gray;
  --ds-cb-title-bar-color: #fff;
  --ds-cb-title-bar-shadow-color: #7c7c7c;
  --ds-cb-title-bar-background-color: rgba(0, 0, 0, 0.25);
  --ds-cb-title-bar-min-height: 75px;
  --ds-cb-quote-footer-color: #999;
  --ds-cb-load-bar-background-color-1: white;
  --ds-cb-load-bar-background-color-2: #cd181f;
  --ds-cb-load-bar-background-color-3: #476e8f;
  --ds-cb-inventory-pagination-button-color: #333;
  --ds-cb-inventory-pagination-button-color--hover: #cd181f;
}

ds-cb-content-main-navigation {
  --ds-link-color: var(--ds-cb-nav-link-color);
}
ds-cb-contact-card {
  --ds-link-color: var(--ds-cb-contact-card-link-color);
}
ds-cb-footer {
  --ds-link-color: var(--ds-cb-footer-link-color);
  --ds-cb-card-border-color: var(--ds-cb-footer-card-border-color);
  --ds-cb-card-header-footer-background-color: var(
    --ds-cb-footer-card-header-footer-background-color
  );
  --ds-cb-image-background-color: var(--ds-cb-footer-background-color);
  --ds-cb-image-border-color: transparent;
}
