.ds-overlay-pane {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

  max-height: 100%;
  max-width: 80vw;
  min-width: 400px;
}
.ds-modal-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
