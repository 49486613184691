.ds-tags {
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-left: 25px;
  padding-right: 25px;

  top: -12px;
  position: relative;
}
a.ds-tag {
  font-size: 0.8em;
  text-decoration: none;

  background-color: #46b8da;
  color: white;
  padding: 4px 8px;
  margin-bottom: 4px;

  display: flex;
  align-items: center;

  border-radius: 3px;
}

a.ds-tag:hover {
  background-color: #39b3d7;
}

a.ds-tag:not(:first-child) {
  margin-left: 4px;
}

a.ds-tag .icon {
  width: 12px;
  display: block;
  margin-right: 4px;
}
