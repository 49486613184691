@media print {
  :root {
    --ds-text-color: #000 !important;
    --ds-link-color: #000 !important;
    --ds-button-color: #000 !important;
    --ds-button-background-color: #fff !important;
    --ds-button-border-color: #000 !important;

    --ds-background-color: #fff !important;
    --ds-main-content-background-color: #fff !important;

    --ds-cb-title-bar-color: #000 !important;
    --ds-cb-title-bar-background-color: #fff !important;

    --ds-cb-card-header-footer-background-color: #fff !important;
    --ds-cb-card-header-footer-color: #000 !important;
    --ds-cb-card-background-color: #fff !important;

    --ds-cb-contact-card-background-color: #fff !important;
    --ds-cb-contact-card-link-color: #000 !important;
  }

  ds-cb-content-main-navigation,
  ds-cb-footer {
    display: none !important;
  }

  ds-cb-title-bar {
    height: auto !important;
    min-height: auto !important;
    overflow: visible !important;

    .background-image,
    .left-column {
      display: none !important;
    }

    h1 {
      text-shadow: none !important;
    }
  }
}
