.ds-button,
a.ds-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--ds-button-color);
  background-color: var(--ds-button-background-color);
  border: 1px solid var(--ds-button-border-color);

  padding: 0.475rem 0.85rem;
  border-radius: 0.25rem;

  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ds-button[disabled],
a.ds-button[disabled] {
  opacity: 0.5;
}

.ds-button.alt,
a.ds-button.alt {
  color: var(--ds-button-background-color);
  background-color: var(--ds-button-color);
  border: 1px solid var(--ds-button-border-color);
}
