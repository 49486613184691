.ds-alert {
  padding: 15px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.ds-alert--warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
