// https://raw.githubusercontent.com/eduardoboucas/include-media/master/dist/_include-media.scss

.ds-container {
  max-width: 1170px;
  width: 95%;
  margin: 0 auto;
}

.ds-title-container {
  max-width: 1170px;
  width: 95%;
}

// this is the spacing for rows and columns.
// the rows and negative margins and the columns
// have positive padding. So two columns next to
// each other will have space between them, but
// a column on the end of the row will not.
$spacing: 15px;

// @function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
// @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
// }
// ds-cb-section {
// position: relative;
// flex-direction: column;
// display: flex;
// margin-top: 0;
// align-items: center;
// margin-left: auto;
// margin-right: auto;
// margin-bottom: 15px;
// background-size: cover;
// justify-content: center;
// }
.ds-row,
ds-cb-content-block-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$spacing;
  margin-left: -$spacing;
}
// row with a single column, helps cut down on the div nesting.
.ds-row-sc {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacing;
}
.ds-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
formly-field {
  label {
    margin-bottom: 3px;
    display: block;
  }
}
formly-field-radio {
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
}
formly-field[class*='ds-col'] {
  display: inline-block;
}
.ds-row > [class*='ds-col'],
ds-cb-content-block-row > [class*='ds-col'],
formly-field[class*='ds-col'] {
  position: relative;
  width: 100%;
  min-height: 1px;
  margin-bottom: $spacing;
  padding-left: $spacing;
  padding-right: $spacing;
}
.ds-field-group {
  formly-field[class*='ds-col'] {
    margin-bottom: 2px;
  }
}
.ds-form-simple-text {
  padding: 0 25px 25px;
}

[class*='ds-col'] {
  position: relative;
  width: 100%;
  min-height: 1px;
}
// Creates each .col-X element to size it for each column
@for $cols from 1 through 12 {
  $percWidth: percentage(($cols)/12);
  .ds-col-#{$cols} {
    flex: 0 0 $percWidth;
    max-width: $percWidth;
  }
}
// does the same as above but creates on for each breakpont.
// .ds-col-BP-X
@each $breakpoint in map-keys($breakpoints) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @for $cols from 1 through 12 {
      $percWidth: percentage(($cols)/12);
      .ds-col-#{$breakpoint}-#{$cols} {
        flex: 0 0 $percWidth;
        max-width: $percWidth;
      }
    }
  }
}
// crates a column who will only size to the content
.ds-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
// same as above but creates on for each breakpoint.
@each $breakpoint in map-keys($breakpoints) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    .ds-col-#{$breakpoint}-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
  }
}
// quick align items
.ds-align-items-center {
  align-items: center !important;
}
.ds-flex-wrap {
  flex-wrap: wrap !important;
}
// Debugging
// [class*='ds-row'] {
// border: 5px dotted green;
// }
// [class*='ds-col'] {
// border: 5px solid red;
// }
// [class*='ds-col-'] {
// border: 5px solid purple;
// }

/* The CSS below is copied from @PlakeSide/bootstrap-scss (https://github.com/PlakeSide/bootstrap-scss/blob/master/utilities/_display.scss) to bring in the display helpers that we need.
 Currently, this only includes d-flex for all breakpoinsts. */
@each $breakpoint in map-keys($breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);
    .d#{$infix}-flex {
      display: flex !important;
    }
  }
}
/* The CSS below is copied from @PlakeSide/bootstrap-scss (https://github.com/PlakeSide/bootstrap-scss/blob/master/utilities/_flex.scss) to bring in the flexbox helpers that we need.
  Currently, this only includes justify-content-end and justify-content-center for all breakpoints. */
@each $breakpoint in map-keys($breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);
    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }
    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }
    .justify-content#{$infix}-center {
      justify-content: center !important;
    }
  }
}
