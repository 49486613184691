@import '~@angular/cdk/overlay-prebuilt.css';
/* The mixins below are copied from @PlakeSide/bootstrap-scss https://github.com/PlakeSide/bootstrap-scss/blob/master/mixins/_breakpoints.scss as they are required by the CSS below the mixins.
  Currently, only a subset of the breakpoint mixins are included. */
:root {
  --ds-text-color: rgb(88, 88, 90);
  --ds-link-color: #cb1c27;
  --ds-button-color: #fff;
  --ds-button-background-color: #cb1c27;
  --ds-button-border-color: #cb1c27;
  --ds-form-control-border: 1px solid #ced4da;
  --ds-form-control-border-color--focus: #cb1c27;
  --ds-form-control-background-color: #fff;
  --ds-form-control-background-color--focus: #fff;
  --ds-form-control-color: #495057;
  --ds-form-control-color--focus: #495057;
  --ds-form-control-padding-x: 0.375rem;
  --ds-form-control-padding-y: 0.75rem;
  --ds-form-input-group-addon-border: 1px solid #ced4da;
  --ds-form-input-group-addon-background-color: #e9ecef;
  --ds-form-input-group-addon-color: #495057;
  --ds-background-color: #333;
  --ds-main-content-background-color: #fff;
  --ds-cb-section-divider-color: #333;
  --ds-cb-section-max-width: 1170px;
  --ds-cb-nav-background-color: #333;
  --ds-cb-nav-link-color: #fff;
  --ds-cb-nav-item-color: #fff;
  --ds-cb-nav-item-color--active: #333;
  --ds-cb-nav-item-color--hover: #333;
  --ds-cb-nav-item-background-color--active: #fff;
  --ds-cb-nav-item-background-color--hover: #fff;
  --ds-cb-nav-sub-menu-background-color: #fff;
  --ds-cb-nav-sub-menu-color: #333;
  --ds-cb-nav-sub-menu-item-border-left--hover: none;
  --ds-cb-nav-text-color: #fff;
  --ds-cb-nav-logo-height: 60px;
  --ds-cb-card-border-color: #333;
  --ds-cb-card-header-footer-background-color: #cb1c27;
  --ds-cb-card-header-footer-color: inherit;
  --ds-cb-card-header-footer-text-align: left;
  --ds-cb-card-background-color: #fff;
  --ds-cb-footer-background-color: #333;
  --ds-cb-footer-link-color: steelblue;
  --ds-cb-footer-text-color: #fff;
  --ds-cb-footer-card-border-color: #333;
  --ds-cb-footer-card-background-color: #cb1c27;
  --ds-cb-footer-card-header-footer-background-color: #cb1c27;
  --ds-cb-contact-card-link-color: steelblue;
  --ds-cb-contact-card-border-color: #e5e5e5;
  --ds-cb-contact-card-border-color--hover: gray;
  --ds-cb-contact-card-background-color: whitesmoke;
  --ds-cb-contact-card-photo-border-color: gray;
  --ds-cb-image-background-color: white;
  --ds-cb-image-border-color: gray;
  --ds-cb-title-bar-color: #fff;
  --ds-cb-title-bar-shadow-color: #7c7c7c;
  --ds-cb-title-bar-background-color: rgba(0, 0, 0, 0.25);
  --ds-cb-title-bar-min-height: 75px;
  --ds-cb-quote-footer-color: #999;
  --ds-cb-load-bar-background-color-1: white;
  --ds-cb-load-bar-background-color-2: #cd181f;
  --ds-cb-load-bar-background-color-3: #476e8f;
  --ds-cb-inventory-pagination-button-color: #333;
  --ds-cb-inventory-pagination-button-color--hover: #cd181f;
}

ds-cb-content-main-navigation {
  --ds-link-color: var(--ds-cb-nav-link-color);
}

ds-cb-contact-card {
  --ds-link-color: var(--ds-cb-contact-card-link-color);
}

ds-cb-footer {
  --ds-link-color: var(--ds-cb-footer-link-color);
  --ds-cb-card-border-color: var(--ds-cb-footer-card-border-color);
  --ds-cb-card-header-footer-background-color: var(
    --ds-cb-footer-card-header-footer-background-color
  );
  --ds-cb-image-background-color: var(--ds-cb-footer-background-color);
  --ds-cb-image-border-color: transparent;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

div {
  position: relative;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.5em;
  font-weight: 400;
  height: 100%;
  color: var(--ds-text-color);
}

input[type=text],
textarea {
  font-family: "Roboto Condensed", sans-serif;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: var(--ds-link-color);
}

a.phone {
  text-decoration: none;
}

.ds-text-left {
  text-align: left !important;
}

.ds-text-right {
  text-align: right !important;
}

.ds-text-center {
  text-align: center !important;
}

@media (min-width: 800px) {
  .ds-text-sm-left {
    text-align: left !important;
  }

  .ds-text-sm-right {
    text-align: right !important;
  }

  .ds-text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .ds-text-md-left {
    text-align: left !important;
  }

  .ds-text-md-right {
    text-align: right !important;
  }

  .ds-text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .ds-text-lg-left {
    text-align: left !important;
  }

  .ds-text-lg-right {
    text-align: right !important;
  }

  .ds-text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .ds-text-xl-left {
    text-align: left !important;
  }

  .ds-text-xl-right {
    text-align: right !important;
  }

  .ds-text-xl-center {
    text-align: center !important;
  }
}
.ds-button,
a.ds-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--ds-button-color);
  background-color: var(--ds-button-background-color);
  border: 1px solid var(--ds-button-border-color);
  padding: 0.475rem 0.85rem;
  border-radius: 0.25rem;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ds-button[disabled],
a.ds-button[disabled] {
  opacity: 0.5;
}

.ds-button.alt,
a.ds-button.alt {
  color: var(--ds-button-background-color);
  background-color: var(--ds-button-color);
  border: 1px solid var(--ds-button-border-color);
}

.ds-container {
  max-width: 1170px;
  width: 95%;
  margin: 0 auto;
}

.ds-title-container {
  max-width: 1170px;
  width: 95%;
}

.ds-row,
ds-cb-content-block-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.ds-row-sc {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
}

.ds-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

formly-field label {
  margin-bottom: 3px;
  display: block;
}

formly-field-radio label {
  display: inline-block;
  margin-bottom: 0px;
}

formly-field[class*=ds-col] {
  display: inline-block;
}

.ds-row > [class*=ds-col],
ds-cb-content-block-row > [class*=ds-col],
formly-field[class*=ds-col] {
  position: relative;
  width: 100%;
  min-height: 1px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.ds-field-group formly-field[class*=ds-col] {
  margin-bottom: 2px;
}

.ds-form-simple-text {
  padding: 0 25px 25px;
}

[class*=ds-col] {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.ds-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.ds-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.ds-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.ds-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.ds-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.ds-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.ds-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.ds-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.ds-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.ds-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.ds-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.ds-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 0) {
  .ds-col-xs-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ds-col-xs-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ds-col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ds-col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ds-col-xs-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ds-col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ds-col-xs-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ds-col-xs-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ds-col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ds-col-xs-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ds-col-xs-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ds-col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 800px) {
  .ds-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ds-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ds-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ds-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ds-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ds-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ds-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ds-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ds-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ds-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ds-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ds-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 960px) {
  .ds-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ds-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ds-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ds-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ds-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ds-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ds-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ds-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ds-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ds-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ds-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ds-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1280px) {
  .ds-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ds-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ds-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ds-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ds-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ds-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ds-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ds-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ds-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ds-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ds-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ds-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1920px) {
  .ds-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ds-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ds-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ds-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ds-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ds-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ds-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ds-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ds-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ds-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ds-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ds-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.ds-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

@media (min-width: 0) {
  .ds-col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
}
@media (min-width: 800px) {
  .ds-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
}
@media (min-width: 960px) {
  .ds-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
}
@media (min-width: 1280px) {
  .ds-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
}
@media (min-width: 1920px) {
  .ds-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
}
.ds-align-items-center {
  align-items: center !important;
}

.ds-flex-wrap {
  flex-wrap: wrap !important;
}

/* The CSS below is copied from @PlakeSide/bootstrap-scss (https://github.com/PlakeSide/bootstrap-scss/blob/master/utilities/_display.scss) to bring in the display helpers that we need.
 Currently, this only includes d-flex for all breakpoinsts. */
.d-flex {
  display: flex !important;
}

@media (min-width: 800px) {
  .d-sm-flex {
    display: flex !important;
  }
}
@media (min-width: 960px) {
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 1280px) {
  .d-lg-flex {
    display: flex !important;
  }
}
@media (min-width: 1920px) {
  .d-xl-flex {
    display: flex !important;
  }
}
/* The CSS below is copied from @PlakeSide/bootstrap-scss (https://github.com/PlakeSide/bootstrap-scss/blob/master/utilities/_flex.scss) to bring in the flexbox helpers that we need.
  Currently, this only includes justify-content-end and justify-content-center for all breakpoints. */
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

@media (min-width: 800px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }
}
@media (min-width: 960px) {
  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }
}
@media (min-width: 1280px) {
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }
}
@media (min-width: 1920px) {
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }
}
.ds-fluid-text,
.ds-cb-html:not(.has-font-size) > *,
ds-cb-link,
ds-cb-contact-card,
ds-cb-staff-card {
  font-size: 1.25rem;
  line-height: 1.45rem;
}
@media (max-width: 1200px) {
  .ds-fluid-text,
.ds-cb-html:not(.has-font-size) > *,
ds-cb-link,
ds-cb-contact-card,
ds-cb-staff-card {
    font-size: calc(1.05rem + 0.2666666667vw);
  }
}

.ds-fluid-text-xs {
  font-size: 1rem;
  line-height: 1rem;
}

.ds-fluid-text-sm {
  font-size: 1rem;
  line-height: 1.15rem;
}

.ds-fluid-text-lg {
  font-size: 1.5625rem;
  line-height: 1.75rem;
}
@media (max-width: 1200px) {
  .ds-fluid-text-lg {
    font-size: calc(1.1125rem + 0.6vw);
  }
}

.ds-fluid-text-xl {
  font-size: 1.875rem;
  line-height: 2rem;
}
@media (max-width: 1200px) {
  .ds-fluid-text-xl {
    font-size: calc(1.175rem + 0.9333333333vw);
  }
}

.ds-fluid-text-xxl {
  font-size: 2.5rem;
  line-height: 2rem;
}
@media (max-width: 1200px) {
  .ds-fluid-text-xxl {
    font-size: calc(1.3rem + 1.6vw);
  }
}

h3.fluid-text {
  font-size: 1.4375rem;
}
@media (max-width: 1200px) {
  h3.fluid-text {
    font-size: calc(1.0875rem + 0.4666666667vw);
  }
}

ds-cb-header h2.x-large {
  font-size: 3.125rem;
  line-height: calc(1.75rem + 1.2666666667vw);
}
@media (max-width: 1200px) {
  ds-cb-header h2.x-large {
    font-size: calc(1.425rem + 2.2666666667vw);
  }
}
ds-cb-header h2.large {
  font-size: 2.1875rem;
  line-height: calc(1.5rem + 1.2666666667vw);
}
@media (max-width: 1200px) {
  ds-cb-header h2.large {
    font-size: calc(1.2375rem + 1.2666666667vw);
  }
}
ds-cb-header h2.medium {
  font-size: 1.875rem;
}
@media (max-width: 1200px) {
  ds-cb-header h2.medium {
    font-size: calc(1.175rem + 0.9333333333vw);
  }
}
ds-cb-header h2.small {
  font-size: 1.5625rem;
}
@media (max-width: 1200px) {
  ds-cb-header h2.small {
    font-size: calc(1.1125rem + 0.6vw);
  }
}
ds-cb-header h2.x-small {
  font-size: 1.25rem;
}
@media (max-width: 1200px) {
  ds-cb-header h2.x-small {
    font-size: calc(1.05rem + 0.2666666667vw);
  }
}

.ds-spacer {
  width: 100%;
  height: 25px;
}

.placeholder {
  height: 100%;
  width: 100%;
  background-color: lightgray;
  text-align: center;
  align-content: center;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control {
  background-color: var(--ds-form-control-background-color);
  color: var(--ds-form-control-color);
  padding: var(--ds-form-control-padding-x) var(--ds-form-control-padding-y);
  border: var(--ds-form-control-border);
}
.form-control:focus {
  border-color: var(--ds-form-control-border-color--focus);
  color: var(--ds-form-control-color--focus);
  background-color: var(--ds-form-control-background-color--focus);
}

.input-group-text {
  border: var(--ds-form-input-group-addon-border);
  background-color: var(--ds-form-input-group-addon-background-color);
  color: var(--ds-form-input-group-addon-color);
}

.ds-overlay-pane {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  max-height: 100%;
  max-width: 80vw;
  min-width: 400px;
}

.ds-modal-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.ds-alert {
  padding: 15px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.ds-alert--warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.ds-tags {
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-left: 25px;
  padding-right: 25px;
  top: -12px;
  position: relative;
}

a.ds-tag {
  font-size: 0.8em;
  text-decoration: none;
  background-color: #46b8da;
  color: white;
  padding: 4px 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

a.ds-tag:hover {
  background-color: #39b3d7;
}

a.ds-tag:not(:first-child) {
  margin-left: 4px;
}

a.ds-tag .icon {
  width: 12px;
  display: block;
  margin-right: 4px;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-default .sb-wrapper {
  color: white;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--button-color) !important;
}
.sb-default .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-default .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-default .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-default .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-default .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-default .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-default .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-default .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-default .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-default .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-default .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-default .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-default .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-default .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-default .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-default .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.sb-default .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-default .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-default .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-default .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  filter: none;
  padding-right: 1em;
  padding-left: 0;
}

ds-cb-section ds-cb:not(:last-child) {
  margin-bottom: 10px;
}

.ds-no-results {
  text-align: center;
  font-weight: bold;
  width: 100%;
  line-height: 2rem;
  background-color: lightgray;
}

.edit-shield {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.is-editing .edit-shield {
  display: block;
}

@media print {
  :root {
    --ds-text-color: #000 !important;
    --ds-link-color: #000 !important;
    --ds-button-color: #000 !important;
    --ds-button-background-color: #fff !important;
    --ds-button-border-color: #000 !important;
    --ds-background-color: #fff !important;
    --ds-main-content-background-color: #fff !important;
    --ds-cb-title-bar-color: #000 !important;
    --ds-cb-title-bar-background-color: #fff !important;
    --ds-cb-card-header-footer-background-color: #fff !important;
    --ds-cb-card-header-footer-color: #000 !important;
    --ds-cb-card-background-color: #fff !important;
    --ds-cb-contact-card-background-color: #fff !important;
    --ds-cb-contact-card-link-color: #000 !important;
  }

  ds-cb-content-main-navigation,
ds-cb-footer {
    display: none !important;
  }

  ds-cb-title-bar {
    height: auto !important;
    min-height: auto !important;
    overflow: visible !important;
  }
  ds-cb-title-bar .background-image,
ds-cb-title-bar .left-column {
    display: none !important;
  }
  ds-cb-title-bar h1 {
    text-shadow: none !important;
  }
}
.ngx-gallery-arrow {
  top: 50vh !important;
}

svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
}

.ds-table {
  width: 100%;
}
.ds-table td,
.ds-table th {
  padding: 5px;
}

[role=button] {
  cursor: pointer;
}