// Copied from https://github.com/PlakeSide/bootstrap-scss/blob/master/utilities/_text.scss#L17
@each $breakpoint in map-keys($breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    .ds-text#{$infix}-left {
      text-align: left !important;
    }
    .ds-text#{$infix}-right {
      text-align: right !important;
    }
    .ds-text#{$infix}-center {
      text-align: center !important;
    }
  }
}
