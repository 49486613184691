@import '~@angular/cdk/overlay-prebuilt.css';
@import 'styles/variables';

@import 'styles/reset';

html {
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 1.5em;
  font-weight: 400;
  height: 100%;
  color: var(--ds-text-color);
}

input[type='text'],
textarea {
  font-family: 'Roboto Condensed', sans-serif;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: var(--ds-link-color);
}

a.phone {
  text-decoration: none;
}

@import 'styles/text';
@import 'styles/button';
@import 'styles/layout';
@import 'styles/fluid-text';
@import 'styles/utils';
@import 'styles/forms';
@import 'styles/modal';
@import 'styles/badges';
@import 'styles/alerts';
@import 'styles/tags';

// Todo Move share buttons cb component and import the theme there
// @import '~@ngx-share/button/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/default/default-theme';

ds-cb-section {
  ds-cb:not(:last-child) {
    margin-bottom: 10px;
  }
}

.ds-no-results {
  text-align: center;
  font-weight: bold;
  width: 100%;
  line-height: 2rem;
  background-color: lightgray;
}

.edit-shield {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.is-editing .edit-shield {
  display: block;
}

@import 'styles/print';

.ngx-gallery-arrow {
  top: 50vh !important;
}

svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
}

.ds-table {
  width: 100%;
  td,
  th {
    padding: 5px;
  }
}

[role='button'] {
  cursor: pointer;
}
