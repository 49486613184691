.ds-spacer {
  width: 100%;
  height: 25px;
}

.placeholder {
  height: 100%;
  width: 100%;
  background-color: lightgray;
  text-align: center;
  align-content: center;
}
